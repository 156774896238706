import logo from './logo.svg';
import './App.css';

import React from 'react';
import MyComponent from './MyComponent';

function App() {
  return (
    <div>
        <h1>React Project</h1>
        <MyComponent />
    </div>
    
  );
}

export default App;
